<script>
export default {
  name: 'SizeGroup'
}
</script>
<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
// import ProductCardPriceSimple from 'public/src/pages/components/productItemV3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import ProductCardPriceSimpleAutoComplianceMode from 'public/src/pages/goods_detail_v2/components/ProductCardPriceSimpleAutoComplianceMode.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail_v3/utils/productDetail.js'
import { Icon } from '@shein-aidc/icon-vue3'

const daEventExpose = daEventCenter.getExposeInstance()
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : { }

const props = defineProps({
  // sizeGroupList: {
  //   type: Array,
  //   default: () => [],
  // },
  cccAttrGroups: {
    type: Array,
    default: () => [],
  },
  onlyRelatedGoods: {
    type: Array,
    default: () => [],
  },
  isPaidUser: {
    type: Boolean,
    default: false,
  },
  from: {
    type: String,
    default: '',
  },
  goodsId: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => {},
  },
  constant: {
    type: Object,
    default: () => {},
  },
  afterSizeScene: {
    type: Boolean,
    default: false
  },
  findPerfectFitScene: {
    type: Boolean,
    default: false
  }
})
let perfectFitShow = ref(false)
let hasAtomInitial = ref(false)
let onlyRelatedGoodsList = ref([])

const emit = defineEmits(['handleClickSizeGroupItem'])

const clickSizeGroupItem = item => {
  if(props.findPerfectFitScene || props.afterSizeScene){
    return emit('handleClickSizeGroupItem', { ...item, findPerfectFitScene: props.findPerfectFitScene || props.afterSizeScene  })
  }
  if (item.isCurrentGroup) return
  emit('handleClickSizeGroupItem', item)
}

const sizegroupStyleData = computed(() => {
  return props.afterSizeScene ? props.onlyRelatedGoods : props.cccAttrGroups
})

// 切skc重置曝光
watch(() => props.goodsId, ()=> {
  hasAtomInitial.value = false
  daEventExpose.reset('size_group_code')
})

const onBeforeOpen = async () => {
  if(hasAtomInitial.value) return
  const { isHitComplianceMode = false } = props.constant
  onlyRelatedGoodsList.value = props.onlyRelatedGoods
  await itemSer.getAtomicInfo({
    goods: onlyRelatedGoodsList.value,
    fields: {
      baseInfo: 1,
      mallInfo: 1,
      prices: 1,
      vipDiscountPrices: 1, // 付费会员
      sheinClubPromotionInfo: 1, // 付费会员标签/付费会员价
      newFlashPromotion: 1, // 新用户价
      promotion: 1, // 服务类标签-【buy more】 filed=promotion
      abPrice: isHitComplianceMode ? 0 : 1, // ab价原子
      estimatedPrice: isHitComplianceMode ? 0 : 1, // 到手价格
      flashZonePromotion: 1,
    },
    isPaid: !!props.isPaidUser,
  })
  await getDetailRecPreTreat({ products: onlyRelatedGoodsList.value })
  // eslint-disable-next-line require-atomic-updates
  hasAtomInitial.value = true
}

const controlSlideExpandStatus = () => {
  if(!props.findPerfectFitScene) return
  perfectFitShow.value = !perfectFitShow.value
  if(perfectFitShow.value) { // 收起时重置曝光
    daEventExpose.reset('size_group_code')
  } 
  daEventCenter.triggerNotice({
    daId: '1-8-6-208',
    bindData: {
      goods_id: props.goodsId,
      click_type: perfectFitShow.value ? 1 : 0,
      location: props.from === 'detail' ? 'page' : 'popup',
    }
  })
}

const getAnalysisData = ({ type = 'click', item }) => {
  let goods_size_list = '' // goods_id`size_group1`theme_type1
  if(item) {
    const { theme_type = '', merge_theme_type = '', displayDesc, goodsId } = item
    goods_size_list = `${goodsId}\`${displayDesc}\`${merge_theme_type || theme_type}`
  } else {
    const arr = props.findPerfectFitScene ? props.onlyRelatedGoods : sizegroupStyleData.value
    goods_size_list = arr.map(item => `${item.goodsId}\`${item.displayDesc}\`${item.merge_theme_type || item.theme_type}`).join(',')
  }
  const size_group_style = props.afterSizeScene ? 'size_attr' : props.findPerfectFitScene ? 'find_your_fit' : 'size_group'
  const data = {
    location: props.from === 'detail' ? 'page' : 'popup',
    source_goods_id: props.goodsId,
    size_group_style: size_group_style,
    goods_size_list
  }
  return {
    id: type === 'click' ? '1-8-6-199' : '1-8-6-198',
    data,
    code: 'size_group_code'
  }
}
</script>

<template>
  <div
    class="goods-size-group__wrapper"
  >
    <div 
      class="product-intro__size-title" 
      :class="{ 'fold-size-title': findPerfectFitScene }"
      aria-hidden="true"
      @click="controlSlideExpandStatus"
    >
      <div class="goods-size__title-txt">
        {{ findPerfectFitScene ? (language.SHEIN_KEY_PC_30097 || 'Find Your Perfect Fit') : language.SHEIN_KEY_PC_29368 }} 
      </div>
      <Icon
        v-if="findPerfectFitScene"
        v-expose="{
          id: '1-8-6-207',
          data: {
            goods_id: goodsId,
            location: from === 'detail' ? 'page' : 'popup'
          }
        }"
        :class="{ 'more-icon': true, 'more-icon-top': perfectFitShow }"
        name="sui_icon_more_down_18px" 
        size="16px" 
      />
    </div>
    <div>
      <template v-if="findPerfectFitScene">
        <ul 
          v-show="perfectFitShow" 
          v-expose="getAnalysisData({ type: 'expose' })"
          class="goods-size__sizes choose-size"
        >
          <SPopover 
            v-for="(sizeGroupListItem, index) in onlyRelatedGoods"
            :key="sizeGroupListItem.goodsId"
            class="find-perfect__popover-item"
            trigger="hover"
            placemen="bottom" 
            :delay-render="300"
            :append-to-body="true"
            :empty-un-show="true"
            @before-open="onBeforeOpen"
          >
            <template #reference>
              <li
                v-tap="getAnalysisData({ type: 'click', item: sizeGroupListItem })"
                class="find-perfect__size-radio"
                type="text"
                @click="clickSizeGroupItem(sizeGroupListItem)"
              >
                <span class="find-perfect__size-radio-inner">
                  {{ sizeGroupListItem.displayDesc }}
                </span>
                <Icon
                  class="icon-more-new"
                  name="sui_icon_more_right_12px_1" 
                  :is-rotate="GB_cssRight" 
                  size="12px" 
                />
              </li>
            </template>
            <div 
              class="product-intro__size-popover-content find-perfect__size-popover-content"
              @click="clickSizeGroupItem(sizeGroupListItem)"
            >
              <template v-if="hasAtomInitial">
                <div
                  class="product-intro__size-popover-img"
                  :style="{
                    backgroundImage: `url(${onlyRelatedGoodsList[index].goods_img})`,
                  }"
                ></div>
                <div class="product-intro__size-popover-wrap">
                  <div class="product-intro__size-popover-stitle">
                    {{ language.SHEIN_KEY_PC_30225 || 'More Options' }}
                  </div>
                  <div class="product-intro__size-popover-gtitle">
                    {{ onlyRelatedGoodsList[index].goods_name }}
                  </div>
                  <div class="product-intro__size-popover-gprice">
                    <ProductCardPriceSimpleAutoComplianceMode
                      :goods-info="onlyRelatedGoodsList[index]"
                      :language="language"
                      :is-paid="isPaidUser"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="find-perfect__size-popover-loading">
                  <div
                    class="la-ball-pulse la-ball-pulse-black"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </template>
            </div>
          </SPopover>
        </ul>
      </template>
      <template v-else>
        <ul 
          v-expose="getAnalysisData({ type: 'expose' })"
          class="goods-size__sizes choose-size"
        >
          <li
            v-for="sizeGroupListItem in sizegroupStyleData"
            :key="sizeGroupListItem.goodsId"
            v-tap="getAnalysisData({ type: 'click', item: sizeGroupListItem })"
            class="product-intro__size-radio"
            :class="{
              'goods-size__sizes-item': true,
              'product-intro__size-radio_active': sizeGroupListItem.isCurrentGroup,
            }"
            type="text"
            @click="clickSizeGroupItem(sizeGroupListItem)"
          >
            <p
              class="product-intro__size-radio-inner goods-size__sizes-item-text"
            >
              {{ sizeGroupListItem.displayDesc }}
              <Icon
                v-if="afterSizeScene"
                name="sui_icon_more_right_14px_1" 
                size="14px"
                :is-rotate="GB_cssRight" 
                :custom-style="{ verticalAlign: '-3px'}"
              />
            </p>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<style lang="less">
.goods-size-group {
  &__wrapper {
    margin-bottom: 0.1rem;
    & .more-icon {
      margin-left: 7px;
    }
    & .more-icon-top {
      transform: rotate(180deg);
    }
    & .fold-size-title {
      cursor: pointer;
    }
    & .size-group-desc{
      font-weight: 400;
      padding-left: 9px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    height: 0.43rem;
    line-height: 0.43rem;
  }
}
.find-perfect {
  &__popover-item {
    margin: 0 10px 10px 0;
  }
  &__size-radio {
    position: relative;
    min-width: 66px;
    min-height: 32px;
    line-height: 16px;
    padding: 8px 14px;
    border: 1px solid #e5e5e5;
    border-radius: 48px;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    cursor: pointer;
    .icon-more-new {
      margin-left: 2px;   
    }
  }
  &__size-radio-inner {
    .text-overflow();
    font-size: 14px;
  }
  &__product-item {
    cursor: pointer;
  }
  &__size-popover-content {
    cursor: pointer;
    min-width: 248px;
    min-height: 56px;
  }
  &__size-popover-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 248px;
    min-height: 56px;
    & .la-ball-pulse >div {
      width: 8px;
      height: 8px;
    }
  }
}
</style>
