<template>
  <div
    ref="COMMON_LOCAL_SIZE_REF"
    class="common-local-size__units"
    :class="{
      'common-local-size__unitsOption': multiSelectOption,
    }"
  >
    <div
      class="common-local-size__country"
      :class="{ 'padding-more': paddingMore }"
      :style="style"
      @click="clickHandle"
    >
      <template v-if="!multiLocalSize[value]">
        {{ defaultText || language.SHEIN_KEY_PC_18475 }}
      </template>
      <template v-else>
        {{ value }} {{ language.SHEIN_KEY_PC_14656 }}
      </template>
      <Icon
        v-if="multiSelectOption"
        class="local-size-icon"
        name="sui_icon_more_up_14px_1"
        size="14px"
        @click.stop="clickArrowHandle"
      />
      <Icon
        v-if="!multiSelectOption"
        class="local-size-icon"
        name="sui_icon_more_down_14px_1"
        size="14px"
        @click.stop="clickArrowHandle"
      />
    </div>
    <ul
      v-if="multiSelectOption"
      class="common-local-size__country-box"
      :class="{ 'is-inner': isInner }"
      @mouseleave="multiSelectOption = false"
    >
      <li
        v-if="isNewSizeLocal"
        :class="{
          'common-local-size__country-select': !value,
        }"
        @click="onSelectLocalSize('')"
      >
        {{ language.SHEIN_KEY_PC_24642 }}
        <span>({{ language.SHEIN_KEY_PC_24640 }})</span>
      </li>
      <li
        v-for="(val, key) in multiLocalSize"
        :key="key"
        :class="{
          'common-local-size__country-select':
            key === value,
        }"
        da-event-click="1-8-6-62"
        :data-country="value"
        @click="onSelectLocalSize(key)"
      >
        {{ key }}
      </li>
    </ul>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'CommonSizeSelect',
  components: { Icon },
  props: {
    value: {
      type: String,
      default: ''
    },
    multiLocalSize: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    style: {
      type: Object,
      default() {
        return {
          backgroundColor: '#FFF'
        }
      }
    },
    defaultText: {
      type: String,
      default: ''
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    },
    paddingMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      multiSelectOption: false
    }
  },
  mounted() {
    this.isInner = !!this.$refs['COMMON_LOCAL_SIZE_REF'].closest(
      '.sui-dialog__wrapper'
    )
  },
  methods: {
    onSelectLocalSize(key) {
      this.multiSelectOption = false
      localStorage.setItem('last_select_country', key || 'default')
      this.$emit('update:value', key)
      schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: key || 'default',
          scanType: 'detail_local_country'
        }
      })
    },
    clickHandle() {
      this.multiSelectOption = true
      this.$emit('clickHandle', true)
    },
    clickArrowHandle() {
      this.multiSelectOption = !this.multiSelectOption
    }
  },
  emits: ['update:value', 'clickHandle']
}
</script>

<style lang="less">
.common-local-size__country {
  &.padding-more {
    padding: 0 12px;
  }
}
.common-local-size {
  &__country {
    display: inline-flex;
    align-items: center;
    min-width: 52px;
    height: 26px;
    padding: 0 6px;
    text-align: center;
    line-height: 26px;
    font-size: 12px;
    border-radius: 20px;
    color: @sui_color_gray_dark1;
    border: 1px solid rgba(229, 229, 229, 1);
    font-weight: 400;
    .svgicon {
      margin-left: 4px;
    }
  }
  &__country-box {
    li {
      &:hover {
        background: rgba(245, 244, 245, 1);
      }
    }
    span {
      color: @sui_color_gray_light1;
    }
  }
  &__country-select {
    background: rgba(245, 244, 245, 1);
  }
  &__units {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    vertical-align: bottom;
    .local-size-icon {
      margin-left: 5px;
    }
    ul {
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(242, 242, 242, 1);
      padding: 5px 0;
      top: 29px;
    }
    li {
      width: 220px;
      font-weight: 400;
      height: 36px;
      line-height: 36px;
      .padding-l(16px);
      .text-overflow();
      cursor: pointer;
    }
  }
  &__unitsOption {
    z-index: @zindex-hover;
  }
  &__units-left {
    .right(15px);
  }
  &__units-item {
    margin: 4px 0;
    .margin-l(15px);
    white-space: nowrap;
    display: inline-block;
    .she-radio {
      .margin-r(2px);
    }
  }
}
.common-local-size__country-box {
  &.is-inner {
    max-height: 230px;
    overflow-y: scroll;
    .scrollbar-ver();
  }
}
</style>
